import * as Yup from 'yup';

export const comunicationOptions = [
  { label: 'Insights Questions', value: 'Insights Questions' },
  { label: 'Technical Difficulties', value: 'Technical Difficulties' },
  { label: 'My Account Queries', value: 'My Account Queries' },
  { label: 'Other', value: 'Other' },
];

export const contacusIntialValues = {
  time_zone: '',
  schedule_time: null,
  schedule_time_alternate: '',
  communication_type: '',
  message: '',
};

export const contactUsValidation = Yup.object()
  .shape({
    time_zone: Yup.object().required('Please select timezone'),
    schedule_time: Yup.string().required('Please select schedule date'),
    communication_type: Yup.object().required('Please select comunication type'),
  })
  .defined();
export const getCurrentYear = new Date().getFullYear();
