import Api from '../Utils/Axios';

export const getCurrencyList = async () => {
  const response = await Api.get('api/general/get-currency');
  return response.data;
};

export const getLanguageList = async () => {
  const response = await Api.get('api/general/get-language');
  return response.data;
};

export const updateGeneralSetting = async (data) => {
  const response = await Api.post('api/settings/general-setting', data);
  return response?.data;
};

export const twoFactorAuth = async (data) => {
  const response = await Api.post('api/user/update-two-factor-authentication', data);
  return response?.data;
};

export const removeProfileImage = async () => {
  const response = await Api.post('api/user/remove-profile-picture');
  return response?.data;
};

export const updateUserBillingAddress = async (data) => {
  const response = await Api.post('api/user/update-user-billing-address', data);
  return response?.data;
};
export const updateUserNotification = async (data) => {
  const response = await Api.post('api/user/update-notifications', data);
  return response?.data;
};
