import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';

const ButtonComponent = ({ className, btnText, type, ...rest }) => {
  return (
    <Button className={className} type={type} {...rest}>
      {btnText}
    </Button>
  );
};

ButtonComponent.propTypes = {
  className: PropTypes.string,
  btnText: PropTypes.string,
  type: PropTypes.string,
};

export default ButtonComponent;
