import { useQuery } from '@tanstack/react-query';
import { getCountries, getIndustries, getPositions, getRegions, getServices } from '../Api/auth';
import { getCurrencyList, getLanguageList } from '../Api/settings';
import { getYearList } from '../Api/charts';
import { getUserDetails, getUserPaidOrFree } from '../Api/profile';

// import { getUserPaidOrFree } from '../Api/profile';

export const useGetIdustriesHook = () =>
  useQuery({
    queryKey: ['register-industries'],
    queryFn: getIndustries,
    select: (data) => [...data.data.map((item) => ({ value: item._id, label: item.name }))],
  });

export const useGetPositionsHook = () =>
  useQuery({
    queryKey: ['register-positions'],
    queryFn: getPositions,
    select: (data) => [...data.data.map((item) => ({ value: item._id, label: item.name }))],
  });

export const useGetCountriesHook = () =>
  useQuery({
    queryKey: ['register-countries'],
    queryFn: getCountries,
    select: (data) => [...data.data.map((item) => ({ value: item._id, label: item.name, img: item.img }))],
  });

export const useGetRegionsHook = () =>
  useQuery({
    queryKey: ['register-regions'],
    queryFn: getRegions,
    select: (data) => [...data.data.map((item) => ({ value: item._id, label: item.name }))],
  });

export const useGetServicesHook = () =>
  useQuery({
    queryKey: ['register-services'],
    queryFn: getServices,
    select: (data) => [...data.data.map((item) => ({ ...item, value: item._id, label: item.name }))],
  });

export const useGetYearsHook = (startYear, endYear) => {
  var currentYear = endYear,
    years = [];
  // startYear = startYear || 2013;
  while (startYear <= currentYear) {
    const indYear = startYear++;
    years.push({ value: indYear, label: indYear });
  }
  const yearSorting = years.sort((a, b) => {
    if (a.value > b.label) {
      return -1;
    } else if (a.value < b.label) {
      return 1;
    }
    return 0;
  });
  return yearSorting;
};

export const useGetCurrencyListHook = () =>
  useQuery({
    queryKey: ['get-currency'],
    queryFn: getCurrencyList,
    select: (data) => [...data.data.map((item) => ({ value: item?.toLowerCase(), label: item }))],
  });

export const useGetLanguageListHook = () =>
  useQuery({
    queryKey: ['get-language'],
    queryFn: getLanguageList,
    select: (data) => [...data.data.map((item) => ({ value: item?.code, label: item?.language }))],
  });

export const useGetUserPaidOrFreeHook = () => {
  return useQuery({
    queryKey: ['get-profile-paid-or-free'],
    queryFn: getUserPaidOrFree,
    select: (data) => data?.data?.is_paid || false,
    // select: () => true,
  });
};
// useQuery({
//   queryKey: ['get-profile-paid-or-free'],
//   queryFn: getUserPaidOrFree,
//   // select: (data) => data?.data?.is_paid || false,
//   select: () => true,
// });

export const useGetYearList = () =>
  useQuery({
    queryKey: ['get-language'],
    queryFn: getYearList,
    select: (data) => [...data.data.map((item) => ({ value: item?.code, label: item?.language }))],
  });

export const useGetProfile = () =>
  useQuery({
    queryKey: ['hook/user/get-profile'],
    queryFn: getUserDetails,
    throwOnError: (error) => {
      console.log('Error', error);
    },
  });
