import React, { Suspense, lazy, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { ROUTES } from './constant';
import { UserContext } from '../Context/UserContext';
import { useGetProfile, useGetUserPaidOrFreeHook } from '../Hooks/useGeneralApis';
import PageLoader from '../Components/PageLoader';

const DashbaordPage = lazy(() => import('../pages/dashboard'));
const Profile = lazy(() => import('../pages/Profile'));
const Setting = lazy(() => import('../pages/Setting'));
const CountryProfile = lazy(() => import('../pages/countryProfile'));
const RegionProfile = lazy(() => import('../pages/regionProfile'));
const Favourites = lazy(() => import('../pages/favourites'));
const WatchList = lazy(() => import('../pages/watchList'));
const Charts = lazy(() => import('../pages/charts'));
const PrivateRoute = lazy(() => import('./PrivateRoute'));
const Layout = lazy(() => import('../Components/Layout/Layout'));
const SubscribeModal = lazy(() => import('../Components/SubscribeModel'));
const InsightDetails = lazy(() => import('../pages/insightsDetails'));

const Logout = () => {
  const history = useNavigate();
  useEffect(() => {
    localStorage.clear();
    history(ROUTES.LOGIN);
    // window.location.assign('/');
  }, []);
};

const UserDashboardRoutes = () => {
  const { isLoading: isLoadingProfileData, data: userProfileData } = useGetProfile();

  const { data: useGetUserTypeHookToken } = useGetUserPaidOrFreeHook();

  const [show, setShow] = useState(false);

  const openSubscriptionModel = () => setShow(true);

  const closeSubscriptionModel = () => setShow(true);
  return (
    <Suspense fallback={<PageLoader type='fullPage' />}>
      <UserContext.Provider
        value={{
          userProfileData,
          isLoadingProfileData,
          openSubscriptionModel,
          closeSubscriptionModel,
          is_paid_user: useGetUserTypeHookToken,
        }}
      >
        <div className='layout-container'>
          <Layout>
            <Routes>
              <Route exact path={ROUTES.DASBOARD} element={<PrivateRoute Component={DashbaordPage} />} />
              <Route exact path={ROUTES.LOGOUT} element={<Logout />} />
              <Route path={ROUTES.SETTING} element={<PrivateRoute Component={Setting} />} />
              <Route path={ROUTES.PROFILE} element={<PrivateRoute Component={Profile} />} />
              <Route path={ROUTES.COUNTRYPROFILE} element={<PrivateRoute Component={CountryProfile} />} />
              <Route path={ROUTES.REGIONPROFILE} element={<PrivateRoute Component={RegionProfile} />} />
              <Route path={ROUTES.CHARTS} element={<PrivateRoute Component={Charts} />} />
              <Route path={ROUTES.FAVOURITES} element={<PrivateRoute Component={Favourites} />} />
              <Route path={ROUTES.WATCHLIST} element={<PrivateRoute Component={WatchList} />} />
              <Route path={ROUTES.INSIGHTSDETAILS} element={<PrivateRoute Component={InsightDetails} />} />
            </Routes>
          </Layout>
        </div>
        {show && <SubscribeModal show={show} setShow={setShow} />}
      </UserContext.Provider>
    </Suspense>
  );
};
export default UserDashboardRoutes;
