export const ROUTES = {
  HOME: '/home',
  USERDASHBOARD: '/user/*',
  SIGNUP: '/signup',
  LOGIN: '/',
  EMAILVERIFY: '/email-verify',
  FORGOTPASSWORD: '/forgot-password',
  RESETPASSWORD: '/reset-password/:token',
  PROFILE: '/profile',
  SETTING: '/setting',
  DASBOARD: '/dashboard/*',
  LOGOUT: 'logout',
  COUNTRYPROFILE: '/country-profile/:id',
  REGIONPROFILE: '/region-profile/:id',
  CHARTS: '/charts',
  USERDAHBOARDMAP: '/user/dashboard/map',
  USERDAHBOARDCHART: '/dashboard/chart/:id',
  FAVOURITES: '/favourites',
  WATCHLIST: '/watch-list',
  INSIGHTSDETAILS: '/insights-details',
};

export const URL_ENUMS = {
  HOME: '/',
  SIGNUP: '/signup',
  LOGIN: '/login',
  EMAILVERIFY: '/email-verify',
  FORGOTPASSWORD: '/forgot-password',
  RESETPASSWORD: '/reset-password/:token',
  PROFILE: '/user/profile',
  SETTING: '/user/setting',
  DASBOARD: '/dashboard/*',
  LOGOUT: 'logout',
  COUNTRYPROFILE: '/country-profile/:id',
  REGIONPROFILE: '/region-profile/:id',
  CHARTS: '/user/charts',
  USERDAHBOARDMAP: '/user/dashboard/map',
  USERDAHBOARDCHART: '/user/dashboard/chart/:id',
  FAVOURITES: '/user/favourites',
  WATCHLIST: '/user/watch-list',
};
