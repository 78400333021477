import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Skeleton } from '@mui/material';

const SkeletonComponent = () => {
  const isMaxWidth = useMediaQuery({ maxWidth: 1536 });
  return (
    <Skeleton
      animation='wave'
      className='skeleton-container'
      variant='rounded'
      width='100%'
      height={isMaxWidth ? 'calc(100vh - 310px)' : 'calc(100vh - 345px)'}
    />
  );
};

export default SkeletonComponent;
