import Api from '../Utils/Axios';

export const getIndustries = async () => {
  const response = await Api.get('api/general/get-all-industries');
  return response.data;
};

export const getPositions = async () => {
  const response = await Api.get('api/general/get-all-positions');
  return response.data;
};

export const getCountries = async () => {
  const response = await Api.get('api/general/get-all-countries');
  return response.data;
};

export const getRegions = async () => {
  const response = await Api.get('api/general/get-all-region');
  return response.data;
};

export const getServices = async () => {
  const response = await Api.get('api/general/get-all-services');
  return response.data;
};

export const postRegister = async (data) => {
  const response = await Api.post('api/auth/register', data);
  return response.data;
};

export const postRemoveRegister = async (data) => {
  const response = await Api.post('api/auth/remove_register', data);
  return response.data;
};

export const postLogin = async (data) => {
  const response = await Api.post('api/auth/login', data);
  return response.data;
};

export const postResendEmailVerification = async (data) => {
  const response = await Api.post('api/auth/resend_verify_email_register', data);
  return response.data;
};

export const postForgotPassword = async (data) => {
  const response = await Api.post('api/auth/forgot_password', data);
  return response.data;
};

export const postResetPassword = async (data) => {
  const response = await Api.post('api/auth/reset_password', data);
  return response.data;
};

export const postChangePassword = async (data) => {
  const response = await Api.post('api/auth/change_password', data);
  return response.data;
};

export const postVerifyOtp = async (data) => {
  const response = await Api.post('api/auth/verify_otp', data);
  return response.data;
};
