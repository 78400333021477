import Api from '../Utils/Axios';

export const postTimeSeriesData = async (data) => {
  const response = await Api.post('api/chart/get-timeline-chart-data', data);
  return response.data;
};

export const postChartInsightsData = async (data) => {
  const response = await Api.post('api/chart/insights', data);
  return response.data;
};

export const getYearList = async () => {
  const response = await Api.get('api/general/get-year-range');
  return response.data;
};

export const postDataByRadarChart = async (data) => {
  const response = await Api.post('api/chart/get-radar-chart-data', data);
  return response.data;
};

export const postDataByBoxPloaChart = async (data) => {
  const response = await Api.post('api/chart/get-boxplot-chart-data', data);
  return response.data;
};

export const postDataByScatterChart = async (data) => {
  const response = await Api.post('api/chart/get-scatter-chart-data', data);
  return response.data;
};

export const postColumnChartData = async (data) => {
  const response = await Api.post('api/chart/get-bar-chart-data', data);
  return response.data;
};

export const chartsHistoryApi = async (data) => {
  const response = await Api.post('api/chart/history', data);
  return response.data;
};

export const postAddToFavouriteChartData = async (data) => {
  const response = await Api.post('api/favourite/add', data);
  return response.data;
};
