import toast from 'react-hot-toast';
import './toast.css';

const ToastComponent = ({ type, message, duration = 3000 }) => {
  if (type === 'error') {
    return toast.error(message || 'Server Error!', {
      position: 'top-right',
      duration: duration,
      className: 'toast-hot-container',
      style: {
        zIndex: '9999999 !important',
      },
    });
  }
  if (type === 'success') {
    return toast.success(message || 'Server Error!', {
      position: 'top-center',
      duration: duration,
      className: 'toast-hot-container',
      style: {
        zIndex: '9999999 !important',
      },
    });
  }
  if (type === 'loading') {
    return toast.loading(message || 'Server Error!', {
      position: 'bottom-right',
      duration: duration,
      className: 'toast-hot-container',
      style: {
        zIndex: '9999999 !important',
      },
    });
  }
};

export default ToastComponent;
