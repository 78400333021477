/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { ButtonComponent, ToastComponent } from '../../Components';
import { Col, Modal, Row } from 'react-bootstrap';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import SelectBox from '../../Components/SelectBox';
import { comunicationOptions, contactUsValidation, contacusIntialValues } from './constant';
import { AppContext } from '../../Hooks/useContextHook';
import DateTimePicker from '../../Components/dateTimePicker';
import './style.css';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import { useMutateContactUsFormHook } from './hook/useApiHook';
import moment from 'moment-timezone';
import { AiOutlineClose } from 'react-icons/ai';

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
  'Europe/Berlin': 'Frankfurt',
};

const ContactUsForm = () => {
  const contactUsPost = useMutateContactUsFormHook();
  const { options } = useTimezoneSelect({ labelStyle, timezones });
  const { open, setOpen } = useContext(AppContext);

  const handleClose = () => setOpen(!open);

  const handleSubmitContact = (values) => {
    const schedule = moment.tz(values?.schedule_time);
    const schedule_alternate = moment.tz(values?.schedule_time_alternate);

    const reqData = {
      timezone: values?.time_zone?.value,
      timezone_label: values?.time_zone?.label,
      schedule_time: schedule || '',
      schedule_time_alternate: schedule_alternate || '',
      communication_type: values?.communication_type?.value,
      message: values?.message,
    };
    contactUsPost.mutate(reqData, {
      onSuccess: (response) => {
        ToastComponent({
          type: 'success',
          message: response?.message || 'Created',
        });
        handleClose();
      },
      onError: (error) => {
        ToastComponent({
          type: 'error',
          message: error?.response?.data?.message || 'Server Error!',
        });
      },
    });
  };

  return (
    <div className='modal-container'>
      <Modal
        show={open}
        backdrop={'static'}
        backdropClassName='contact-us-model-backdrop'
        onHide={handleClose}
        keyboard={true}
        centered
        size='lg'
        className='contact-us-model'
      >
        <Modal.Header className='model-header'>
          <Modal.Title>
            <span className='d-block model-main-title contactus-title'>Contact Us</span>
          </Modal.Title>
          <span className='model-header-action-wrap' onClick={handleClose}>
            <AiOutlineClose className='model-header-action-icon' />
          </span>
        </Modal.Header>
        <div className='px-3 py-2 modal-body-content'>
          <Formik
            initialValues={contacusIntialValues}
            validationSchema={contactUsValidation}
            onSubmit={handleSubmitContact}
          >
            {({ handleSubmit, setFieldValue, isSubmitting, values }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className='input-label-div'>
                    <label className='input-label-div-label'>Choose Time Zone</label>
                    <div className='input-label-div-input-div'>
                      <Field component={SelectBox} placeholder='Choose' name='time_zone' options={options} />
                    </div>
                    <ErrorMessage name='time_zone' component='div' className='input-label-div-error-msg' />
                  </div>
                  <div className='input-label-div  mt-2 mb-1'>
                    <Row className='dashboard-page-container-row'>
                      <Col md='6' xs='6' sm='6' lg='6' xl='6' xxl='6' className='mb-1'>
                        <label className='input-label-div-label'>Choose Date and Time</label>
                        <Field component={DateTimePicker} placeholder='Select Date/Time' name='schedule_time' />
                      </Col>
                      <Col md='6' xs='6' sm='6' lg='6' xl='6' xxl='6'>
                        <label className='input-label-div-label'>Choose Date and Time (Alternate)</label>
                        <Field
                          component={DateTimePicker}
                          placeholder='Select Date/Time'
                          name='schedule_time_alternate'
                        />
                      </Col>
                    </Row>
                  </div>
                  {/* <div className='input-label-div mt-1'>
                      <label className='input-label-div-label'>Choose Date and Time (Alternate)</label>
                      <Row className='dashboard-page-container-row'>
                        <Col md='6' xs='6' sm='6' lg='6' xl='6' xxl='7' className='mb-1'>
                          <Field component={DatePicker} placeholder='dd:mm:yyyy' name='alternativeData' />
                          <ErrorMessage name='alternativeData' component='div' className='input-label-div-error-msg' />
                        </Col>
                        <Col md='6' xs='6' sm='6' lg='6' xl='6' xxl='5'>
                          <div className='input-label-div-input-div'>
                            <Field component={TimePicker} placeholder='00:00' name='alternativeTime' />
                          </div>
                          <ErrorMessage name='alternativeTime' component='div' className='input-label-div-error-msg' />
                        </Col>
                      </Row>
                    </div> */}
                  <div className='input-label-div'>
                    <label className='input-label-div-label'>Communication Type</label>
                    <div className='input-label-div-input-div'>
                      <Field
                        component={SelectBox}
                        placeholder='Choose'
                        name='communication_type'
                        options={comunicationOptions}
                      />
                    </div>
                    <ErrorMessage name='communication_type' component='div' className='input-label-div-error-msg' />
                  </div>
                  <div className='input-label-div mt-2'>
                    <label className='input-label-div-label'>Message (Optional)</label>
                    <div className='input-label-div-input-div'>
                      <textarea
                        className='dashboard-page-main-container-right-side-contact-form-textarea'
                        type='text'
                        onChange={(e) => setFieldValue('message', e.target.value)}
                        name='message'
                        placeholder='Write a message'
                      />
                    </div>
                    <ErrorMessage name='message' component='div' className='input-label-div-error-msg' />
                  </div>
                  <div className='contact-form-btn-box mt-1'>
                    <ButtonComponent
                      className='contact-form-submit-btn'
                      type='submit'
                      btnText={isSubmitting ? 'Loading...' : 'Submit'}
                      disabled={isSubmitting}
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default ContactUsForm;
