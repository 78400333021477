import React from 'react';
import smallLogo from '../Assets/sidebar/image.svg';
import './style.css';

const PageLoader = ({ type = 'component', customStyles }) => {
  const loaderStyles = {
    display: 'grid',
    placeItems: 'center',
    width: '100%',
    ...(customStyles || {}),
  };

  return (
    <div
      style={
        type === 'component' ? { height: '70vh', ...loaderStyles } : { width: '100%', height: '90vh', ...loaderStyles }
      }
    >
      {type == 'component' ? (
        <img src={smallLogo} alt='smallLogo' className='pareto-loader' />
      ) : (
        <img src={smallLogo} alt='smallLogo' className='pareto-loader' />
      )}
    </div>
  );
};

export default PageLoader;
