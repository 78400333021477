import React from 'react';
import { Card } from 'react-bootstrap';
import './dropdown.css';
import Select from 'react-select';

const DropdownComponent = ({ placeholder, options, display, ...rest }) => {
  const dot = (color = 'transparent') => ({
    color: color,
    margin: '0px',
    padding: '0px',
    ':focus-visible': {
      outline: 'none !important',
      boxShadow: 'none !important',
    },
    outline: 'none !important',
  });

  return (
    <Card className='dropdown-continer-component'>
      <Card.Body
        className={display === 'flex' ? 'dropdown-continer-component-body' : 'dropdown-continer-component-column-body'}
      >
        <Card.Text className='dropdown-continer-component-placeholder'>{placeholder}</Card.Text>
        <Select
          options={options}
          maxMenuHeight='150px'
          {...rest}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ? '#1B243A' : '#1B243A',
              ':hover': {
                borderColor: '#1B243A',
              },
              ':focus-visible': {
                outline: 'none !important',
                boxShadow: 'none !important',
              },
              outline: 'none !important',
              background: '#1B243A',
              color: '#fff',
              fontSize: '14px',
              margin: '0px',
              padding: '0px',
            }),
            option: (styles, { isDisabled, isSelected }) => ({
              ...styles,
              backgroundColor: isSelected ? '#10182d' : '#1B243A',
              margin: '0px',
              fontSize: '14px',
              color: isSelected ? '#fff' : '#fff',
              ':active': {
                color: '#FFF',
              },
              ':hover': {
                backgroundColor: '#10182d',
                color: '#fff',
              },
              cursor: isDisabled ? 'not-allowed' : 'default',
              '::-webkit-scrollbar': {
                display: 'none',
              },
            }),
            container: (styles) => ({
              ...styles,
              ...dot('#fff'),
            }),
            valueContainer: (styles) => ({
              ...styles,
              ...dot('#fff'),
            }),
            input: (styles) => ({
              ...styles,
              ...dot('#fff'),
            }),
            placeholder: (styles) => ({ ...styles, ...dot('#fff') }),
            singleValue: (styles) => ({ ...styles, ...dot('#fff') }),
          }}
        />
      </Card.Body>
    </Card>
  );
};

export default DropdownComponent;
