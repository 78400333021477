import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routings from './routes';
import { HelmetProvider } from 'react-helmet-async';
import { Toaster } from 'react-hot-toast';
import 'react-datepicker/dist/react-datepicker.css';
import { AppContext } from './Hooks/useContextHook';
import { useGetCountriesHook, useGetRegionsHook, useGetServicesHook } from './Hooks/useGeneralApis';
import { getYearList } from './Api/charts';
import { useQuery } from '@tanstack/react-query';
import ContactUsForm from './Components/ContactUsModalForm';
import BookDemo from './Components/BookDemo';

function App() {
  const [open, setOpen] = React.useState(false);
  const [bookDemoOpen, setBookDemoOpen] = React.useState(false);

  const { isLoading: isServicesLoading, data: services } = useGetServicesHook();

  const { isLoading: isRegionsLoading, data: regions } = useGetRegionsHook();

  const { isLoading: isCountriesLoading, data: countries } = useGetCountriesHook();

  const { isLoading: isYearLoading, data: years } = useQuery({
    queryKey: ['get-year-range'],
    queryFn: getYearList,
  });

  return (
    <div className='App'>
      <AppContext.Provider
        value={{
          services: services,
          isServicesLoading: isServicesLoading,
          regionOptions: regions,
          isRegionsLoading: isRegionsLoading,
          countriesOptions: countries,
          isCountriesLoading: isCountriesLoading,
          yearOptions: years,
          isYearLoading: isYearLoading,
          setOpen,
          open,
          bookDemoOpen,
          setBookDemoOpen,
        }}
      >
        <HelmetProvider>
          <Toaster />
          <Router>
            <Routings />
            <ContactUsForm />
            <BookDemo />
          </Router>
        </HelmetProvider>
      </AppContext.Provider>
    </div>
  );
}

export default App;
