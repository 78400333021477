import Api from '../Utils/Axios';

export const getUserDetails = async () => {
  const response = await Api.get('api/user/get-profile');
  return response.data;
};

export const getUserPaidOrFree = async () => {
  const response = await Api.get('api/user/get-profile-paid-or-free');
  return response.data;
};

export const postAnalyticsPreferences = async (data) => {
  const response = await Api.post('api/user/update-analytical-preference', data);
  return response.data;
};

export const postUserInformation = async (data) => {
  const response = await Api.post('api/user/update-profile', data);
  return response.data;
};

export const postProfilePicture = async (data) => {
  const response = await Api.post('api/user/update-profile-pic', data);
  return response.data;
};

export const postPaidUserRequest = async (data) => {
  const response = await Api.post('api/user/paid-user-request', data);
  return response.data;
};

export const postUpgradeUserAccountRequest = async (data) => {
  const response = await Api.post('api/general/save-subscription-request', data);
  return response.data;
};
