import * as Yup from 'yup';

export const comunicationOptions = [
  { label: 'Insights Questions', value: 'Insights Questions' },
  { label: 'Technical Difficulties', value: 'Technical Difficulties' },
  { label: 'My Account Queries', value: 'My Account Queries' },
  { label: 'Other', value: 'Other' },
];

export const bookDemoIntialValues = {
  time_zone: '',
  schedule_time: null,
  schedule_time_alternate: '',
  preferred_meet_service: '',
  explore_feature: '',
};

export const bookDemoValidation = Yup.object()
  .shape({
    time_zone: Yup.object().required('Please select timezone'),
    schedule_time: Yup.string().required('Please select schedule date'),
    preferred_meet_service: Yup.string().required('Please select comunication type'),
  })
  .defined();
export const getCurrentYear = new Date().getFullYear();
