import React from 'react';
import { useFormikContext } from 'formik';
import Select from 'react-select';
import './style.css';
import PropTypes from 'prop-types';

const SelectBox = ({ placeholder, field, options, customClass, isOptionDisabled, ...rest }) => {
  const { setFieldValue } = useFormikContext();

  return (
    <div className={`select-input-div custome-select-box-class ${customClass}`}>
      <Select
        {...field}
        {...rest}
        menuShouldScrollIntoView={false}
        maxMenuHeight='150px'
        options={options}
        onChange={(values) => {
          setFieldValue(field.name, values);
        }}
        isOptionDisabled={isOptionDisabled}
        placeholder={placeholder}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            // borderColor: state.isFocused ? '#1B243A' : '#1B243A',
            border: '0px',
            ':hover': {
              borderColor: '#1B243A',
              border: '0px',
            },
            ':focus-visible': {
              outline: 'none !important',
              boxShadow: 'none !important',
              border: 'none !important',
            },
            ':focus': {
              outline: 'none !important',
              boxShadow: 'none !important',
              border: 'none !important',
            },
            outline: 'none !important',
            background: '#1B243A',
            color: '#fff',
            fontSize: '12px',
            // margin: '0px',
            // padding: '0px',
          }),
          option: (styles, { isDisabled, isSelected }) => ({
            ...styles,
            backgroundColor: isSelected ? '#10182d' : '#1B243A',
            margin: '0px',
            fontSize: '12px',
            color: isSelected ? '#fff' : '#fff',
            ':active': {
              color: '#FFF',
            },
            ':hover': {
              backgroundColor: '#10182d',
              color: '#fff',
            },
            cursor: isDisabled ? 'not-allowed' : 'default',
          }),
        }}
      />
    </div>
  );
};

SelectBox.propTypes = {
  placeholder: PropTypes.string,
  field: PropTypes.any,
  options: PropTypes.array,
  customClass: PropTypes.string,
};

export default SelectBox;
