import Api from '../Utils/Axios';

export const postIndexWise = async (data) => {
  const response = await Api.post('api/dashboard/get-indexwise-data', data);
  return response.data;
};

export const postDataByCountries = async (data) => {
  const response = await Api.post('api/dashboard/get-data-by-country', data);
  return response.data;
};

export const postContactUsData = async (data) => {
  const response = await Api.post('api/general/save-contact-us', data);
  return response.data;
};

export const postInsightsData = async (data) => {
  const response = await Api.post('api/dashboard/get-insights-data', data);
  return response.data;
};

export const postDataByRegions = async (data) => {
  const response = await Api.post('api/dashboard/get-data-by-region', data);
  return response.data;
};

export const postDataByRegionsCountrysRanking = async (data) => {
  const response = await Api.post('api/dashboard/get-country-ranking-list', data);
  return response.data;
};

export const getDataByInsights = async (data) => {
  const response = await Api.post('api/dashboard/get-insights-data', data);
  return response.data;
};

export const getCitiesByCountryCode = async (data) => {
  const response = await Api.post('api/general/get-cities-by-country', data);
  return response.data;
};

export const addToFavourite = async (data) => {
  const response = await Api.post('api/favourite/add', data);
  return response.data;
};

export const removeToFavourite = async (data) => {
  const response = await Api.post('api/favourite/remove', data);
  return response.data;
};

export const addSubscriptionRequest = async (data) => {
  const response = await Api.post('api/general/save-subscription-request', data);
  return response.data;
};

export const PostBookDemo = async (data) => {
  const response = await Api.post('api/general/save-book-demo', data);
  return response.data;
};
