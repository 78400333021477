import React, { Suspense, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ROUTES } from './constant';
import PageLoader from '../Components/PageLoader';
import UserDashboardRoutes from './UserDashboardRoutes';
const GuestRoute = lazy(() => import('./GuestRoute'));
const Signup = lazy(() => import('../pages/authentication/resgister'));
const Login = lazy(() => import('../pages/authentication/login'));
const EmailVerify = lazy(() => import('../pages/authentication/verifyEmail'));
const ForgotPassword = lazy(() => import('../pages/authentication/forgotPassword'));
const ResetPassword = lazy(() => import('../pages/authentication/resetPassword'));

export default function Routings() {
  return (
    <React.Fragment>
      <Suspense fallback={<PageLoader type='fullPage' />}>
        <Routes>
          <Route exact path={ROUTES.SIGNUP} element={<GuestRoute Component={Signup} />} />
          <Route index exact path={ROUTES.LOGIN} element={<GuestRoute Component={Login} />} />
          <Route exact path={ROUTES.EMAILVERIFY} element={<GuestRoute Component={EmailVerify} />} />
          <Route exact path={ROUTES.FORGOTPASSWORD} element={<GuestRoute Component={ForgotPassword} />} />
          <Route exact path={ROUTES.RESETPASSWORD} element={<GuestRoute Component={ResetPassword} />} />
        </Routes>
      </Suspense>
      <Routes>
        <Route path={ROUTES.USERDASHBOARD} element={<UserDashboardRoutes />} />
      </Routes>
    </React.Fragment>
  );
}
