import React, { useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { ErrorMessage, useFormikContext } from 'formik';
import { DateTimePicker } from '@mui/x-date-pickers';
import './style.css';
import { useEffect } from 'react';

const DateAndTimePicker = ({ field, placeholder }) => {
  const { setFieldValue } = useFormikContext();
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.readOnly = true;
    }
  }, [inputRef]);
  return (
    <>
      <div className='input-label-div-input-div date-time-picker-custome'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            {...field}
            inputRef={inputRef}
            name={field?.name}
            disablePast={true}
            // minDate={new Date('2024,03,15')}
            format='YYYY/MM/DD HH:MM'
            value={field?.values}
            onChange={(value) => {
              setFieldValue(field?.name, JSON.stringify(value?.$d));
            }}
            placeholder={placeholder}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onKeyDown={(e) => e.preventDefault()}
            slotProps={{
              textField: {
                onClick: () => setIsOpen(true),
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <ErrorMessage name={field?.name} component='div' className='input-label-div-error-msg' />
    </>
  );
};

export default DateAndTimePicker;
